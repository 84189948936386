import { ApiService, HttpService, sleep } from "table";
import {
  AReportDataDto,
  AReportDto,
  CreateAReportDto,
} from "@/modules/analytical-reports/common/types";
import { AReportSaveData } from "@/modules/analytical-reports/services/ApiAReportsService/common/types/StatusAReport";
import { AReportsExtraInfo } from "@/modules/analytical-reports/services/ApiAReportsService/common/types/AReportsExtraInfo";
import { ApiListWrapper } from "table/dist/services/Api/types/ApiListWrapper";
import { AReportRepositoryDto } from "@/modules/analytical-reports/components/AnalyticalReportsCreateReport.vue";

class ApiAReportsService {
  getQueryParams = ApiService.getQueryParams;

  getAReport(areport_id: string | number) {
    return HttpService.get<AReportDto>(
      "/api/areports?" +
        this.getQueryParams({
          action: "read",
          areport_id,
        }),
    ).then((x) => {
      // x.json.data.areport_state = "published";
      // x.json.data.areport_creator = "creator";
      // x.json.data.areport_name = `${
      //   x.json.data.areport_name || ""
      // } ${dateFormat(x.json.data.areport_ts, "rusDateTime")}`;
      return x;
    });
  }

  getAReportDataList() {
    return HttpService.get<ApiListWrapper<AReportDataDto>>(
      "/api/areports?" + this.getQueryParams({ action: "list" }),
    );
  }

  getAReportParams(areport_type: string) {
    return HttpService.get<AReportRepositoryDto>(
      "/api/repository/areports?" +
        this.getQueryParams({
          action: "get_params",
          areport_type,
        }),
    );
  }

  createAReport(data: CreateAReportDto) {
    return HttpService.post<AReportDto>("/api/areports?action=create", {
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  saveAReport(areport_id: number, data: AReportSaveData) {
    return HttpService.post<AReportDto>(
      "/api/areports?" +
        this.getQueryParams({
          areport_id,
          action: "to_save",
        }),
      {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  }

  publishAReport(areport_id: number, data: AReportSaveData) {
    return HttpService.post<AReportDto>(
      "/api/areports?" +
        this.getQueryParams({
          areport_id,
          action: "to_publish",
        }),
      {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  }

  archiveAReport(areport_id: number) {
    return HttpService.post<never>(
      "/api/areports?" +
        this.getQueryParams({
          areport_id,
          action: "to_archive",
        }),
      {
        body: JSON.stringify({}),
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  }

  removeAReport(areport_id: number) {
    return HttpService.post<never>(
      "/api/areports?" +
        this.getQueryParams({
          areport_id,
          action: "delete",
        }),
      {
        body: JSON.stringify({}),
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  }

  recalculateAReport(areport_id: number, data: CreateAReportDto) {
    return HttpService.post<AReportDto>(
      "/api/areports?" +
        this.getQueryParams({
          areport_id,
          action: "recalculate",
        }),
      {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  }

  async exportToXlsx(areport_id: (string | number) | (string | number)[]) {
    window.open(
      "/api/areports?" +
        this.getQueryParams({
          action: "export_to_xlsx",
          areport_id,
        }),
      "_blank",
    );
    // заглушка для ожидания выгрузки
    return await sleep(1000);
  }

  getExtraInfo() {
    const url = `/api/areports?action=get_extra_info`;
    return ApiService.useCache(url, () =>
      HttpService.get<AReportsExtraInfo>(url),
    );
  }
}

export default new ApiAReportsService();
